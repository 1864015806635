import React, { useState } from 'react'
import styles from './accordion.module.css'

const Accordion = ({ items, children, ...props }) => {
  return (items && !!items.length) && (
    <div {...props}>
      {props.title && (
        <h1>{props.title}</h1>
      )}

      {props.subtitle && (
        <p>{props.subtitle}</p>
      )}

      {children && (
        <>{children}</>
      )}

      <div className=''>
        {items.map((item, index) => (
          <AccordionItem {...item} key={`accordion-item-${index}`} />
        ))}
      </div>
    </div>
  )
}

const AccordionItem = ({ title, content, ...props }) => {
  const [isOpen, setIsOpen] = useState(props.isOpen || false);

  const toggleAccordionItem = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className='lead mb-8'>
      <button className={`js-accordion-button gtm--faq ${styles.button} ${isOpen ? styles.expanded + ' expanded' : ''} flex w-full align-top justify-between text-left focus:text-primary focus:outline-none`} onClick={toggleAccordionItem}>
        <h3 className={`font-normal lead ${isOpen ? 'underline text-primary-medium' : ''} pointer-events-none`}>{title}</h3>
      </button>

      <div className={`pt-2 pb-2 pr-12 small ${isOpen ? 'block' : 'hidden'}`}>
        {content}
      </div>
    </div>
  )
}

export default Accordion
