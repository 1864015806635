import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Figure from '../components/figure'
import Button from '../components/button'
import BlockContent from '../components/block-content'
import GlobalUSP from '../components/global-usp'
import { BlockContentToText } from '../utils/block-content-to-text'
import ProductLayoutStyles from '../components/product-layout.module.css'
import Reviews from '../components/reviews'
import Accordion from '../components/accordion'
import Carousel from '../components/carousel'
import { faqToAccordion, mapEdgesToNodes } from '../utils/helpers'
import BlogPostPreview from '../components/blog-post-preview'
import Physician from '../components/physician'
import Breadcrumbs from '../components/breadcrumbs'
import paymentIcon from '../images/pay.svg'
import HyphenatedTitle from '../components/hyphenatedTitle'
import PorterbuddyWidget from '../components/porterbuddy'

const ProductTemplate = props => {
  const { data, errors } = props
  const product = data && data.product
  const blog = data && data.blog && mapEdgesToNodes(data.blog)
  const pageDescriptionBlock = data && data.site && data.site.pageDescriptionBlock
  const metaDescription = (product.seo && product.seo.description) || BlockContentToText(product.lead)
  const showPorterbuddyWidget = product.shippingInfo && product.shippingInfo.showPorterbuddyWidget
  let hideCta = false

  if (product.cta.hideOutsideVideoOpeningHours) {
    hideCta = true

    if (data && data.site && data.site.openingHours) {
      const today = new Date()
      const currentDay = today.getDay()
      const currentDayOpeningHours = data.site.openingHours.find(day => day.day === currentDay.toString());
      if (currentDayOpeningHours && currentDayOpeningHours.opensAt && currentDayOpeningHours.closesAt) {
        const currentTime = today.toTimeString().substr(0,5)
        hideCta = currentTime < currentDayOpeningHours.opensAt || currentTime >= currentDayOpeningHours.closesAt ? true : false
      }
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof window.dataLayer !== 'undefined' && product.sku) {
      window.dataLayer.push({
        ecommerce: {
          detail: {
            products: [{
              id: product.sku,
              price: product.defaultPrice,
              category: product.category.title
            }]
          }
        }
      })
    }
  }, [])

  const BuyButton = (props) => (
    <div className='flex flex-row items-center'>
      {product.cta && product.cta.text && product.cta.url && !hideCta && (
        <Button
          href={product.cta.url}
          className='mr-6'
          rounded={true}
          variant='fill'
          color='black'
          size={`${props.size ? props.size : 'medium'}`}
        >
          {product.cta.text}
        </Button>
      )}

      {hideCta && product.cta.openingHoursClosedText && (
        <h5 className="mr-6 max-w-screen-xxs">{product.cta.openingHoursClosedText}</h5>
      )}

      <div className='flex flex-col flex-wrap justify-center'>
        {product.defaultPrice && (
          <div className='md:mb-2 text-black font-medium'>Kun {product.defaultPrice},–</div>
        )}
        {product.priceText && (
          <div className='flex flex-row text-xs items-center'>
            <img src={paymentIcon} alt='' className='mr-2' />
            {product.priceText}
          </div>
        )}
      </div>
    </div>
  )

  return (
    <>
      <Breadcrumbs crumbs={[
        product.category,
        product
      ]} />
      <div className={ProductLayoutStyles.wrapper}>
        {errors && <SEO title='GraphQL Error' />}
        {product && <SEO page={product} description={metaDescription} image={product.image} />}

        <div className={ProductLayoutStyles.intro}>
          <div className={ProductLayoutStyles.introInner}>
            <h1 className='text-black text-4xl md:text-5xl lg:text-4xl xl:text-5xl xxl:text-6xl leading-tight font-bold mt-10'>
              <HyphenatedTitle text={product.title} />
            </h1>

            {product.lead && <BlockContent className='lead mb-8' blocks={product.lead} />}

            {product.usp && (
              <ul className='list-disc list-inside mb-4'>
                {product.usp.map((usp, i) => (
                  <li className='mb-2 small' key={`usp-${i}`}>{usp}</li>
                ))}
              </ul>
            )}

            {product.pushText && <p><b>{product.pushText}</b></p>}

            <div className='hidden lg:block mb-4'>
              <BuyButton size='large'/>
            </div>

            <div className='js-reviews-shortcut pb-8'></div>

            {showPorterbuddyWidget && <PorterbuddyWidget className="bg-primary" /> }

            {product.shippingInfo && product.shippingInfo.text && (
              <BlockContent className='pt-4 pb-8 text-xs bg-primary' blocks={product.shippingInfo.text} />
            )}
          </div>
        </div>
        {showPorterbuddyWidget && (
          <div className='lg:hidden fixed w-full z-10 bottom-0 py-4 px-5 bg-primary'>
            <BuyButton size='medium' />
          </div>
        )}
        {!showPorterbuddyWidget && (
          <div className='lg:hidden sticky z-10 top-0 py-4 px-5 bg-primary'>
            <BuyButton size='medium' />
          </div>
        )}

        <div className={ProductLayoutStyles.content}>
          <div className='bg-primary-background-sand'>
            {product.images.length > 1 && (
              <div className='mb-8 md:mb-16 pt-6 md:pt-12 lg:pt-24'>
                <Carousel buttons={false} className='pl-5 md:pl-16'>
                  {product.images.map((image, i) => (
                    <Figure className={`w-4/5 flex-auto flex-shrink-0 ${i === product.images.length - 1 ? '' : 'mr-5 md:mr-16'}`} node={image} key={image.asset.id} aspectRatio={1} />
                  ))}
                </Carousel>
              </div>
            )}

            {product.images.length == 1 && (
              <div className='mb-8 md:mb-16'>
                {product.images.map(image => (
                  <Figure className='w-full' node={image} key={image.asset.id} aspectRatio={1} />
                ))}
              </div>
            )}

            <div className='px-5 md:px-12'>
              <div className='max-w-screen-sm mx-auto'>
                <GlobalUSP compact={true} className='grid md:grid-row-2 md:grid-cols-2 pb-8 md:pb-16 gap-5' />
              </div>
            </div>
          </div>

          <div className='relative overflow-hidden'>
            <div className='radial-gradient bottom right z-0'></div>
            <div className='py-20 lg:py-32 px-5 md:px-24 relative'>
                <div className='max-w-screen-sm mx-auto'>
                  {product.body && (
                    <div className='mb-20'>
                      <h2 className='font-display display text-black'>Slik fungerer det</h2>
                      <BlockContent className='paragraph-3' blocks={product.body} />
                    </div>
                  )}
                  {product.howItWorksText && (
                    <div className='max-w-screen-xs'>
                      {product.howItWorksText.map((row, i) => (
                        <div key={i} className='mb-12 last:mb-0 flex flex-col'>
                          {row.image && <Figure node={row.image} className='w-6 mb-4 flex-shrink-0' />}
                          <div className='flex-1'>
                            <h3 className='h4 font-body font-bold'>{row.title}</h3>
                            <BlockContent blocks={row.text} className='text-sm' />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
            </div>
          </div>


          <Reviews
            className='bg-primary-background-beige py-20 lg:py-32'
            title='Du trenger ikke ta vårt ord for det'
            showTotals={true}
            showStars={false}
          />

          {product.experts && product.experts.length > 0 && (
            <section id='eksperter' className='flex flex-col bg-primary-background-sand md:justify-center p-5 py-20 md:p-16'>
              <div className='max-w-screen-sm mx-auto'>
                <h3 className='font-body font-bold text-black mb-8'>
                  Våre leger
                </h3>

                {product.experts.map((expert, i) => (
                  <Physician {...expert} key={i} />
                ))}
              </div>
            </section>
          )}

          {product.category && product.category.longDescription && (
            <div className='bg-white'>
              {product.category.image && (
                <Figure node={product.category.image} />
              )}
              <div className='py-20 lg:py-32 px-5 md:px-12'>
                <div className='max-w-screen-sm mx-auto'>
                  <h2 className='font-body font-bold text-black mb-4'>
                    Informasjon om kategorien {product.category.title.toLowerCase()}
                  </h2>
                  <BlockContent className='paragraph-3' blocks={product.category.longDescription} />
                </div>
              </div>
            </div>
          )}

          {product.faq.length > 0 && (
            <div className='bg-primary-background-sand py-20 lg:py-32 px-5 md:px-12'>
              <Accordion items={faqToAccordion(product.faq)} className='max-w-screen-sm mx-auto'>
                <h2 className='font-display display mb-4'>Spørsmål og svar fra dere</h2>
                <p className='lead mb-12'>Dette høres kanskje for enkelt ut til å være sant? Her får du svarene! Kontakt oss, se vår FAQ eller send oss en mail hvis du har andre spørsmål</p>
              </Accordion>
            </div>
          )}

          {blog && blog.length > 0 && (
            <div className='bg-white py-20 lg:py-32 px-5 md:px-12'>
              <div className='max-w-screen-sm mx-auto'>
                <h2 className='font-display display mb-10 md:mb-20 text-center md:text-left'>
                  Artikler fra bloggen
                </h2>

                <div className='grid md:grid-cols-2 gap-10'>
                  {blog.map(node => (
                    <BlogPostPreview compact className='mb-4 w-full' key={node.id} {...node} />
                  ))}
                </div>
              </div>
            </div>
          )}

          {pageDescriptionBlock && pageDescriptionBlock.text && (
            <div className='bg-primary-background py-20 lg:py-32 px-5 md:px-12'>
              <div className='max-w-screen-sm mx-auto'>
                {pageDescriptionBlock.image && (
                  <Figure node={pageDescriptionBlock.image} className='mb-16' />
                )}
                {pageDescriptionBlock.title && (
                  <h2 className='font-body font-bold mb-4'>
                    {pageDescriptionBlock.title}
                  </h2>
                )}
                <BlockContent className='paragraph-3' blocks={pageDescriptionBlock.text} />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ProductTemplate

export const query = graphql`
  query ProductPageQuery($id: String!, $categoryId: String!) {
    product: sanityProduct(id: {eq: $id}) {
      id
      shopifyId
      images {
        asset {
          id
          fluid(maxWidth: 1280) {
            ...GatsbySanityImageFluid
          }
        }
        alt
      }
      category {
        title
        longDescription: _rawLongDescription(resolveReferences:{maxDepth:100})
        slug {
          current
        }
        image {
          asset {
            id
            fluid(maxWidth: 1280) {
              ...GatsbySanityImageFluid
            }
          }
          alt
        }
      }
      sku
      defaultPrice
      priceText
      title
      usp
      faq {
        question
        _rawAnswer(resolveReferences:{maxDepth:100})
      }
      pushText
      cta {
        text
        url
        hideOutsideVideoOpeningHours
        openingHoursClosedText
      }
      shippingInfo {
        showPorterbuddyWidget
        text: _rawText(resolveReferences:{maxDepth:100})
      }
      howItWorksText {
        title
        text: _rawText(resolveReferences:{maxDepth:100})
        image {
          asset {
            id
            fluid(maxWidth: 200) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      experts {
        name
        image {
          alt
          asset {
            fluid(maxWidth: 452) {
              ...GatsbySanityImageFluid
            }
          }
        }
        description: _rawDescription
        position
      }
      lead: _rawLead(resolveReferences:{maxDepth:100})
      body: _rawBody(resolveReferences:{maxDepth:100})
      slug {
        current
      }
      seo {
        title
        description
      }
    }

    blog: allSanityBlog(filter: {
      category: {id: {eq: $categoryId}}
    }, sort: {
      fields: _createdAt,
      order: DESC
    }, limit: 2) {
      edges {
        node {
          ...BlogPostPreview
        }
      }
    }

    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      pageDescriptionBlock {
        title,
        text: _rawText(resolveReferences:{maxDepth:100})
        image {
          asset {
            id
            fluid(maxWidth: 1280) {
              ...GatsbySanityImageFluid
            }
          }
        }
      },
      openingHours {
          day
          opensAt
          closesAt
      }
    }
  }
`
