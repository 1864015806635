import React from 'react'
import Figure from './figure'
import BlockContent from './block-content'

const Physician = ({ image, position, name, description, ...props }) => {
  const showIntercom = (e) => {
    e.preventDefault()

    if (typeof window.Intercom !== 'undefined') {
      window.Intercom('show')
    }
  }

  return (
    <div className='mx-auto flex flex-col md:flex-row items-center mb-16 max-w-screen-md'>
      <div className='w-full md:w-56 mb-4'>
        {image && (
          <Figure className='' node={image} aspectRatio={0.8} />
        )}
      </div>

      <div className={`flex flex-col justify-center md:ml-12 w-full md:w-3/5`}>
        {name && (
          <div className='text-black paragraph-2 font-bold'>{name}</div>
        )}
        {position && (
          <div className='text-xs font-bold uppercase mb-4'>
            {position}
          </div>
        )}
        {description && (
          <BlockContent blocks={description} className='small' />
        )}
      </div>
    </div>
  )
}

export default Physician
