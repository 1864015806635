import React, { useEffect } from 'react'
import Helmet from 'react-helmet'

const PorterBuddyWidget = ({ className }) => {
  useEffect(() => {
    (function(){var a=document.createElement("script");a.type="text/javascript";a.async=false;a.src="https://widget.porterbuddy.com/porterbuddy-widget.js";var b=document.getElementsByTagName("script")[0];b.parentNode.insertBefore(a,b);}());

    window.porterbuddy = {
      token: 'vUbZlWNyzZJzW2PRLUuMZ79DhFKcCVIGH7adRWNn',
      view: 'availability',
      language: 'NO',
      discount: 8000,
      text: {
        availabilityPostcodeSuccess: 'Få levert hjem {{delivery}} for {{price}}.',
        availabilitySuccessCountdown: 'Bestill innen {{remaining}}',
      },
    }
  }, [])

  return (
    <div className={`porterbuddy-wrapper ${className}`}>
      <Helmet>
        <link href="https://widget.porterbuddy.com/porterbuddy-widget.css" rel="stylesheet" type="text/css"/>
      </Helmet>

      <div id="porterbuddy-widget" className="max-w-screen-xxs"></div>
    </div>
  )
}

export default PorterBuddyWidget
